import React from 'react';
import "./About.css";

const About = () => {
  return (
    <div className='about-page'>
      <h1>Songül Kınık Kimdir</h1>
      <div className='about-row'>
        <div className='about-img-container'>
          <img className='about-img' src='images/Günbatımı TÜYB 70X100 1996.jpg' alt='' loading='eager'/>
          <div className='about-img-overlay'>
            <p className='about-img-caption'>Lirik ve estetik görsel pasajlarla yaşadığımız şehrin ve insanların çarpıklaşması, çarpık  kentleşmesi ve bunun içinde kaybolan insanlar ,kadın portrelerinde gelenekçi bir yaklaşımın yanında ,toplumda kadının üstlendiği rol  estetik kaygılarla yol almıştır.

              Şiirsel bir  ifadeyle şekillenir.Gerçekte bunlar herhangi bir nesnenin resmi değil, bilinç altındaki estetik kaygılardı kuşkusuz. İç dünyamın bir konseriydi belki.

              Kolorist lekelerden oluşan spontane tuşlarla soyutlamacı bir ifade ile renk  paletini geniş tutup boyaların rastlantısal esprisini yakalayabilme çabası vardır.Boyasal anlatımdaki  renk tonlarının farklılığı ile zıtlıklarına dayanmaktadır. Yatay ve dikey çizgilerle dinamizmi sağlama savaşıdır.

              Bu oluşumun yolu doğal , zorlamasız ve rengin nesneleri parçalayan dokular ve bu bağlantıların yarattığı resimsel hazdır.
            </p>
          </div>
        </div>
        <div className='about-col1'>
          <h3>Serüvenim</h3>
          <p>1973 yılında Kahramanmaraş’ın Göksun ilçesinin Fındık köyünde doğdu.
            Adige (Çerkes) kökenli olup KANOKA ailesine mensuptur. İlk ve orta eğitimini Göksun’da tamamladıktan sonra
            1997 yılında Selçuk Üniversitesi Eğitim Fakültesi Resim-İş Eğitimi bölümünü bitirdi.
            Sonrasında 1998 yılında MEB’e bağlı olarak görsel sanatlar öğretmenliği yapmaya başlayan sanatçı
            sırasıyla Adana-Feke, Adana-Seyhan Kilis-Merkez (Kilis 7 Aralık Üniversitesi Misafir Öğretim Görevliliği),
            Adana-Seyhan’da görev yaptıktan sonra halen Adana Çukurova Emine Nabi Menemencioğlu Anadolu Lisesi’nde görev yapmaktadır.
            Sanatçı, bu 24 yıllık sürede anaokulundan üniversiteye eğitimin bütün aşamalarında eğitimci olarak bulunmuştur.</p>
        </div>
        <div className='about-col2'>
          <h3>Sergilerim</h3>
          <h4>Karma Sergilerim</h4>
          <p>2003 Adana Kafkas Kültür Derneği Sergi Salonu</p>
          <p>2004 Adana Kafkas Kültür Derneği Sergi Salonu</p>
          <p>2005 Adana Kafkas Kültür Derneği Sergi Salonu</p>
          <p>2007 Adana Kafkas Kültür Derneği Sergi Salonu</p>
          <p>2016 Çankaya Belediyesi Çağdaş Sanatlar Merkezi</p>
          <p>2019 Adana Çerkes Kültür Derneği Sergi Salonu</p>
          <p>2019 Ağustos Maykop Kartinina Galeria Sergi Salonu</p>
          <p>2020 Adana Ressamlar Derneği 24 Kasım Karma Resim Sergisi</p>
          <p>2021 Ankara 1864’ten İzler Karma Resim Sergisi</p>
          <p>2022 Kayseri’’ Bunun Adı Soykırım’’ adlı Resim Sergisi Kadir Has Kültür Merkezi</p>
          <p>2022 İstanbul ‘’ N’art 2022 İstanbul Sanatçı Buluşması’’ Bakırköy  Belediyesi    Yunus  Emre Kültür Merkezi</p>
          <p>2022 İstanbul  Art  Exhibition  Bağlarbaşı Kültür Merk</p>
          <p>2022 Öğretmenler Günü Karma Resim Sergisi ARD KAMAN SANAT  GALERİSİ  Sergi Salonu ADANA</p>
          <h4>Kişisel Sergilerim</h4>
          <p>2007 “Kafdağı Çocukları” Fotoğraf Sergisi AKKD Sergi Salonu</p>
          <p>2011 “Karma-Karışık” Resim Sergisi Kilis 7 Aralık Üniversitesi Sergi Salonu kilis</p>
          <p>2023 ‘Sessizlik’ Resim Sergisi ARD KAMAN SANAT GALERİSİ Sergi Salonu ADANA</p>
        </div>
      </div>
    </div>
  );
}

export default About;
