import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Analytics } from '@vercel/analytics/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <Analytics beforeSend={(e) => {
      const url= new URL(e.url)
      url.searchParams.delete('secret')
      return{
        ...e,
        url: url.toString(),
      }
    }} />
  </React.StrictMode>
);

reportWebVitals();
