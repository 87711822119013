import React from 'react'
import "./Home.css";
import Works from '../Works/Works';

export default function Home() {
  return (
    <><div className='homepage'>
      <div className='homepage-column1'>
        <img className='home-img' src="images/Asalet.jpg" alt="Resim 1"  loading='eager'/>
        <img className='home-img2' src="images/pp.jpg" alt="Resim 2" loading='eager' />
      </div>
      <div className='header'>
        <h1>Songül Kınık</h1>
        <h4>Eğtimci - Sanatçı</h4>
        <p>1973 yılında Kahramanmaraş’ın Göksun ilçesinin Fındık köyünde doğdu.</p>
        <p>Adige (Çerkes) kökenli olup KANOKA ailesine mensuptur.</p>
        <p>İlk ve orta eğitimini Göksun’da tamamladıktan sonra </p>
        <p>1997 yılında Selçuk Üniversitesi Eğitim Fakültesi</p>
        <p>Resim-İş Eğitimi bölümünü bitirdi.</p>
      </div>
    </div>
    <Works />
    </>
  )
}
