import React from 'react';
import { Link } from 'react-router-dom'; // react-router-dom eklemeyi unutmayın
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='copyright'>
                 &copy; {new Date().getFullYear()}{' '}
                <Link className='link' to="https://endlesstech.org" target="_blank" rel="noopener noreferrer">
                    Endless Software ∞
                </Link>
                <p className='link'>-</p>
                <Link className='link' to="https://janberkaltay.dev/" target="_blank" rel="noopener noreferrer">
                    Janberk Altay
                </Link>
            </div>
        </div>
    );
}
export default Footer;
