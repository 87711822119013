import React from 'react';

const Activities = () => {
  return (
    <div>
      Activities
    </div>
  );
}

export default Activities;
