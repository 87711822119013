import React from 'react';
import "./Contact.css";
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const Contact = () => {
  return (
    <div className='contact-page'>
      <div>
        <img className='home-img' src="ESERLER/Ğağa 35x 50 22 TÜYB.jpg" alt="Resim 1" loading='eager'/>
        </div>
        <div className='contact-col'>
        <div className='mail'>
        <h1>MAİL ADRESİM</h1>
        <div className='email-container'>
          <EmailIcon style={{ fontSize: 60, marginRight: 12, backgroundColor: "green" }} />
          <a href="mailto:songulkanoka@gmail.com" style={{ color: "white", textDecoration: "none", backgroundColor: "green" }}>songulkanoka@gmail.com</a>
        </div>
      </div>
      <div className='social'>
        <h1>SOSYAL MEDYA HESAPLARIM</h1>
        <div className='social-icons'>
          <div className='instagram'>
          <InstagramIcon style={{ fontSize: 60, marginRight: 12, backgroundColor: "green" }} />
            <a href="https://www.instagram.com/songulkinik/" target="_blank" rel="noopener noreferrer">
              songulkinik
            </a>
          </div>
          <div className='facebook'>
          <FacebookIcon style={{ color: "blue", fontSize: 60, marginRight: 12, backgroundColor: "green" }} />
            <a href="https://www.facebook.com/profile.php?id=696019406" target="_blank" rel="noopener noreferrer">
              Songül Kınık
            </a>
          </div>
        </div>
      </div>
        </div>
        <div>
        <img className='home-img' src="ESERLER/Pışınawe 21x29,7 2020 Kağıt üzerine Pastel Boya (8).jpg" alt="Resim 1" loading='eager' />
        </div>
    </div>
  );
}

export default Contact;
