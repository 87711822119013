const worksData = [
    {
        id: 1,
        resim: 'ESERLER/(insanlar) wunağo 50x70 Tuval Yağlıboya.jpg',
        name: 'Wunağo (İnsanlar)',
        dimensions: '50x50 cm',
        year: 2020,
        style: "Tuval Üzerine Yağlıboya"
    },
    // {
    //     id: 2,
    //     resim: 'ESERLER/21x29,7 2019  Kağıt üzerine Pastel Boya.jpg',
    //     name: 'Kağıt üzerine Pastel Boya',
    //     dimensions: '21x29 cm',
    //     year: 2019,
    //     style: "Kağıt üzerine Pastel Boya"
    // },
    {
        id: 3,
        resim: 'ESERLER/17,5X25 Dans 2011 KÜYB.jpg',
        name: 'Dans',
        dimensions: '5x25 cm',
        style: "Kağıt üzerine Tağlı Boya",
        year: 2011,
    },
    {
        id: 4,
        resim: 'ESERLER/24,5X34,5 Döngü KÜAB.jpg',
        name: 'Döngü',
        style: "Kağıt üzerine Akrilik Boya",
        dimensions: '5X34 cm',
        year: 2019,
    },
    // {
    //     id: 5,
    //     resim: 'ESERLER/20,7x29,6  Plastik Üzerine Pastel Boya.jpg',
    //     name: 'Plastik Üzerine Pastel Boya',
    //     style: "Plastik Üzerine Pastel Boya",
    //     dimensions: '7x29 cm',
    //     year: 2020,
    // },
    {
        id: 6,
        resim: 'ESERLER/19x28  portre Kağıt Üzerine Karakalem 2020.jpg',
        name: 'Portre',
        style: "Kağıt Üzerine Karakalem",
        dimensions: '19x28 cm',
        year: 2020,
    },
    {
        id: 7,
        resim: 'ESERLER/18X25 TÜYB  Hüzün 2001.JPG',
        name: 'Hüzün',
        style: "Tuval Üzerine Yağlı Boya",
        dimensions: '18X25 cm',
        year: 2001,
    },

    {
        id: 8,
        resim: 'ESERLER/25x30 mavi 2020 TÜAB.jpg',
        name: 'Mavi',
        style: "Tuval Üzerine Akrilik Boya",
        dimensions: '25x30 cm',
        year: 2020,
    },
    {
        id: 9,
        resim: 'ESERLER/25x32,5 Mavi Kadın 2020 KÜM.jpg',
        name: 'Mavi Kadın',
        style: "KÜM",
        dimensions: '25x32 cm',
        year: 2020,
    },
    {
        id: 10,
        resim: 'ESERLER/29,5x21 Portre 2022 Kağıt Üzerine Karakalem.jpg',
        name: 'Portre ',
        style: "Kağıt Üzerine Karakalem",
        dimensions: '5x21 cm',
        year: 2022,
    },
    {
        id: 11,
        resim: 'ESERLER/29,6x21 2022 daxe KÜTK.jpg',
        style: "KÜTK",
        name: 'Daxe ',
        dimensions: '6x21 cm',
        year: 2022,
    },
    {
        id: 12,
        resim: 'ESERLER/35X40 Oto Portre Kağıt Üzerine Mono Baskı 2022.jpg',
        name: 'Oto Portre',
        style: "Kağıt Üzerine Mono Baskı",
        dimensions: '35X40 cm',
        year: 2022,
    },
    {
        id: 13,
        resim: 'ESERLER/35x50  2020 Telaş Kağıt Üzerine Mürekkep.jpg',
        name: 'Telaş Kağıt Üzerine Mürekkep',
        style: "Kağıt Üzerine Mürekkep",
        dimensions: '35x50 cm',
        year: 2020,

    },
    {
        id: 14,
        resim: 'ESERLER/35x50 Sınırsız Kağıt Üzerine Mürekkep.jpg',
        name: 'Sınırsız',
        style: "Kağıt Üzerine Mürekkep",
        dimensions: '35x50 cm',
        year: 2019,
    },
    {
        id: 15,
        resim: 'ESERLER/50x70 coşku 2018 Tuval Üzerine Yağlıboya.JPG',
        name: 'Coşku ',
        style: "Tuval Üzerine Yağlıboya",
        dimensions: '50x70 cm',
        year: 2018,

    },
    {
        id: 16,
        resim: 'ESERLER/50X70 TÜYB  mavi 2011.JPG',
        name: 'Mavi',
        style: "Tuval Üzerine Yağlıboya",
        dimensions: '50x70 cm',
        year: 2011,
    },
    {
        id: 17,
        resim: 'ESERLER/35X50 Blane TÜYB  2022.jpg',
        name: 'Blane',
        style: "Tuval Üzerine Yağlıboya",
        dimensions: '35x50 cm',
        year: 2022,
    },
    {
        id: 18,
        resim: 'ESERLER/50X70 TÜYB simetri 2011.JPG',
        name: 'Simetri',
        style: "Tuval Üzerine Yağlıboya",
        dimensions: '50x70 cm',
        year: 2011,
    },
    {
        id: 19,
        resim: 'ESERLER/Adam ve Kadın 1997 52x32 Heykel.jpeg',
        name: 'Adam ve Kadın',
        style: "Heykel",
        dimensions: '52x32 cm',
        year: 1997,
    },
    {
        id: 20,
        resim: 'ESERLER/Anne ve Çocuk 2019 50x70 Tuval Üzerine Akrilik.jpg',
        name: 'Anne ve Çocuk',
        style: "Tuval Üzerine Akrilik",
        dimensions: '50x70 cm',
        year: 2019,
    },
    {
        id: 21,
        resim: 'ESERLER/Apsuva 2019 21x29,7 Kağıt Üzerine Pastel Boya.jpg',
        name: 'Apsuva',
        style: "Kağıt Üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2019,
    },
    {
        id: 22,
        resim: 'ESERLER/Asalet 21x29,7 2019  Kağıt üzerine Pastel Boya (5).jpg',
        name: 'Asalet',
        style: "Kağıt üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2019,
    },
    {
        id: 23,
        resim: 'ESERLER/Bağ 21x29,7 2020 Kağıt üzerine Pastel Boya (7).jpg',
        name: 'Bağ',
        style: "Kağıt üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2020,
    },
    {
        id: 24,
        resim: 'ESERLER/Başlangıç 20,7x29,6 Kızlar Plastik Üzerine Pastel Boya.jpg',
        name: 'Başlangıç Kızlar',
        style: "Plastik Üzerine Pastel Boya",
        dimensions: '7x29 cm',
        year: 2020,
    },
    {
        id: 25,
        resim: 'ESERLER/Cegu 2019 50x70 Tuval Üzerine Akrilik.jpeg',
        name: 'Cegu',
        style: "Plastik Üzerine Pastel Boya",
        dimensions: '50x70 cm',
        year: 2019,
    },
    {
        id: 26,
        resim: 'ESERLER/Deniz 2020 20,7x29,6  Plastik Üzerine Pastel Boya.jpeg',
        name: 'Deniz',
        dimensions: '7x29 cm',
        style: "Plastik Üzerine Pastel Boya",
        year: 2020,
    },
    {
        id: 27,
        resim: 'ESERLER/Dığa(güneş) 20,7x29,6  Plastik Üzerine Pastel Boya (2).jpg',
        name: 'Dığa(güneş)',
        style: "Plastik Üzerine Pastel Boya",
        dimensions: '7x29 cm',
        year: 2019,
    },
    {
        id: 28,
        resim: 'ESERLER/Döngü 21x29,7 2019  Kağıt üzerine Pastel Boya (4).jpg',
        name: 'Döngü',
        style: "Kağıt Üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2019,
    },
    {
        id: 29,
        resim: 'ESERLER/Düş 2019 Tuval Üzerine Akrilik.jpg',
        name: 'Düş',
        style: "Tuval Üzerine Akrilik",
        dimensions: '60x40 cm',
        year: 2019,
    },
    {
        id: 30,
        resim: 'ESERLER/Gupşıse 21x29,7 2020 Kağıt üzerine Pastel Boya.jpg',
        name: 'Gupşıse',
        style: "Kağıt üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2020,
    },
    {
        id: 31,
        resim: 'ESERLER/Günbatımı 21x29,7 2019  Kağıt üzerine Pastel Boya (3).jpg',
        name: 'Günbatımı',
        style: "Kağıt üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2019,
    },
    {
        id: 32,
        resim: 'ESERLER/Günbatımı TÜYB 70X100 1996.jpg',
        name: 'Günbatımı TÜYB',
        style: "Tuval üzerine Yağlı Boya",
        dimensions: '70X100 cm',
        year: 1996,
    },
    {
        id: 33,
        resim: 'ESERLER/Ğağa 35x 50 22 TÜYB.jpg',
        name: 'Ğağa',
        style: "Tuval üzerine Yağlı Boya",
        dimensions: '35x50  cm',
        year: 2019,
    },
    {
        id: 34,
        resim: 'ESERLER/Hasret 21x29,7 2019  Kağıt üzerine Pastel Boya (2).jpg',
        name: 'Hasret',
        style: "Kağıt üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2019,
    },
    {
        id: 35,
        resim: 'ESERLER/İki Kadın 2019 50x70 Tuval Üzerine Akrilik.jpeg',
        name: 'iki Kadın',
        style: "Tuval üzerine Akrilik Boya",
        dimensions: '50x70 cm',
        year: 2019,
    },
    {
        id: 36,
        resim: 'ESERLER/İnci 2019 50x70 Tuval Üzerine Akrilik.jpg',
        name: 'inci',
        style: "Tuval üzerine Akrilik Boya",
        dimensions: '50x70 cm',
        year: 2019,
    },
    {
        id: 37,
        resim: 'ESERLER/Kadın 2019 25x35Tuval Üzerne  Akrilik.jpeg',
        name: 'Kadın',
        style: "Tuval üzerine Akrilik Boya",
        dimensions: '25x35 cm',
        year: 2019,
    },
    {
        id: 38,
        resim: 'ESERLER/Kafe 2019 21x29,7 Kağıt Üzerine Pastel Boya.jpeg',
        name: 'Kafe',
        style: "Tuval üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2019,
    },
    {
        id: 39,
        resim: 'ESERLER/Kanoka 2019 50x70 Tuval Üzerine Akrilik.jpg',
        name: 'Kanoka',
        style: "Tuval üzerine Akrilik Boya",
        dimensions: '50x70 cm',
        year: 2019,
    },
    {
        id: 40,
        resim: 'ESERLER/Mavi 2020 25x30Tuval üzerine Akrilik.jpg',
        name: 'Mavi',
        style: "Tuval üzerine Akrilik Boya",
        dimensions: '25x30 cm',
        year: 2020,
    },
    {
        id: 41,
        resim: 'ESERLER/Kardeş Kağıt Üzerine Pastel Boya 2020 47x33,5.jpg',
        name: 'Kardeş',
        style: "Kağıt üzerine Pastel Boya",
        dimensions: '47x33 cm',
        year: 2020,
    },
    {
        id: 42,
        resim: 'ESERLER/Korku 21x29,7 2019 Kağıt Üzerine Pastel Boya.jpg',
        name: 'Korku',
        style: "Kağıt üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2019,
    },
    {
        id: 43,
        resim: 'ESERLER/Nısaşe 2019 50x70 Tuval Üzerne Akrilik.jpg',
        name: 'Nısaşe',
        style: "Tuval üzerine Akrilik Boya",
        dimensions: '50x70 cm',
        year: 2019,
    },
    {
        id: 45,
        resim: 'ESERLER/Nıse 2011 17,5x25 Kağıt Üzerine Yağlıboya.jpeg',
        name: 'Nıse',
        style: "Kağıt üzerine Yağlı Boya",
        dimensions: '5x25 cm',
        year: 2011,
    },
    {
        id: 46,
        resim: 'ESERLER/Nilufer 50x70 2019 Tüzerine Akrilik Boya.jpg',
        name: 'Nilufer',
        style: "Tuval üzerine Akrilik Boya",
        dimensions: '50x70 cm',
        year: 2019,
    },
    {
        id: 47,
        resim: 'ESERLER/Oksana Hakulova 50x 70 2018 Tuval Üzerine Akrilik.jpg',
        name: 'Oksana Hakulova',
        style: "Tuval üzerine Akrilik Boya",
        dimensions: '50x70 cm',
        year: 2018,
    },
    {
        id: 48,
        resim: 'ESERLER/Pışınawe 21x29,7 2020 Kağıt üzerine Pastel Boya (8).jpg',
        name: 'Pışınawe',
        style: "Kağıt üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2020,
    },
    {
        id: 49,
        resim: 'ESERLER/sosrukue 21x29,7 2019  Kağıt üzerine Pastel Boya.jpg',
        name: 'Sosrukue',
        style: "Kağıt üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2019,
    },
    {
        id: 50,
        resim: 'ESERLER/Turkuaz 2019 35x50 Tuval Üzerine Akrilik.jpg',
        name: 'Turkuaz',
        style: "Tuval üzerine Akrilik Boya",
        dimensions: '35x50 cm',
        year: 2019,
    },
    {
        id: 51,
        resim: 'ESERLER/Wunafe 1996 50X70 Tuval üzerine Yağlıboya.jpg',
        name: 'Wunafe',
        style: "Tuval üzerine Yağlı Boya",
        dimensions: '50X70 cm',
        year: 2019,
    },
    {
        id: 52,
        resim: 'ESERLER/Yansıma  20,7x29,6 Plastik Üzerine Pastel Boya 2020.jpeg',
        name: 'Yansıma',
        style: "Plastik üzerine Pastel Boya",
        dimensions: '7x29 cm',
        year: 2020,
    },
    {
        id: 53,
        resim: 'ESERLER/Yokoluş 21x29,7 2019  Kağıt üzerine Pastel Boya (6).jpg',
        name: 'Yokoluş',
        style: "Plastik üzerine Pastel Boya",
        dimensions: '21x29 cm',
        year: 2019,
    },
    {
        id: 54,
        resim: 'ESERLER/ГУАШЭ (GUAŞE)2020 Tuval Üzerine Akrilik 35x50.jpg',
        name: 'ГУАШЭ (GUAŞE)',
        style: "Tuval Üzerine Akrilik ",
        dimensions: '35x50 cm',
        year: 2020,
    },
    {
        id: 55,
        resim: 'ESERLER/К1УНЭ (KUNE)2020 Tuval Üzerine  Akrilik 35x50 (1).jpg',
        name: 'К1УНЭ (KUNE)',
        style: "Tuval Üzerine Akrilik ",
        dimensions: '35x50 cm',
        year: 2020,
    },
    {
        id: 56,
        resim: 'sergi/50x70 çırpınma tüyb 2011  1.JPG',
        name: 'Çırpınma',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '50x70 cm',
        year: 2019,
    },
    {
        id: 57,
        resim: 'sergi/50X70 yansımalar 2011 tüyb 2.JPG',
        name: 'yansımalar',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '50X70 cm',
        year: 2011,
    },
    {
        id: 58,
        resim: 'sergi/Asalet 35x50 tüab 2022 3.jpg',
        name: 'Asalet',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '35x50 cm',
        year: 2022,
    },
    {
        id:59,
        resim: 'sergi/Blane 35x50 tüab 2022   4.jpg',
        name: 'Blane',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '35x50 cm',
        year: 2022,
    },
    {
        id: 60,
        resim: 'sergi/Denge 2011 50x70 Tüyb 6.jpg',
        name: 'Denge',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '50x70 cm',
        year: 2011,
    },

    {
        id: 61,
        resim: 'sergi/Fındık 2011 35x50 tüyb   11.jpg',
        name: 'Fındık',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '35x50 cm',
        year: 2011,
    },
    {
        id: 62,
        resim: 'sergi/Şağdi  2022 tüab 100x80  23.jpg',
        name: 'Şağdi',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '100x80 cm',
        year: 2022,
    },
    {
        id: 63,
        resim: 'sergi/Şelağue  100X70 tüab 2022   24.jpg',
        name: 'Şelağue',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '100X70 cm',
        year: 2022,
    },
    {
        id: 64,
        resim: 'sergi/Çığlık 35x50 tüyb 2011  5.jpg',
        name: 'Çığlık',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '35x50 cm',
        year: 2011,
    },
    {
        id: 65,
        resim: 'sergi/Derinlik 25x35 tüab 2021  7.jpg',
        name: 'Derinlik',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '25x35 cm',
        year: 2019,
    },
    {
        id: 66,
        resim: 'sergi/Dışe  tüab 25x35 2021  8.jpg',
        name: 'Dışe',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '25x35 cm',
        year: 2019,
    },
    {
        id: 67,
        resim: 'sergi/Düş 2019 tüab 50X70    9.jpg',
        name: 'Düş',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '50X70 cm',
        year: 2019,
    },
    {
        id: 68,
        resim: 'sergi/Etkileşim 40x60 tüyb 2015   10.jpg',
        name: 'Etkileşim',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '40x60 cm',
        year: 2015,
    },
    {
        id: 69,
        resim: 'sergi/Göç tüab 2021 50x70   12.jpg',
        name: 'Göç tüab',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '50x70 cm',
        year: 2021,
    },
    {
        id: 70,
        resim: 'sergi/GUAŞE  35X50 Tüab 2020   13.jpg',
        name: 'GUAŞE',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '35X50 cm',
        year: 2020,
    },
    {
        id: 71,
        resim: 'sergi/GUNE 35x50 Tuval üzerine Akrilik 2020  14.jpg',
        name: 'GUNE',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '35x50 cm',
        year: 2020,
    },
    {
        id: 72,
        resim: 'sergi/Hüzün  2011 50x70 Tüyb 15.jpg',
        name: 'Hüzün',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '50x70 cm',
        year: 2011,
    },
    {
        id: 73,
        resim: 'sergi/Karmaşık  50x70 Tüyb 2011   16.jpg',
        name: 'Karmaşık',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '50x70 cm',
        year: 2011,
    },
    {
        id: 74,
        resim: 'sergi/Kırmızı 35x50 tüab 2021  17.jpg',
        name: 'Kırmızı',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '35x50 cm',
        year: 2021,
    },
    {
        id: 75,
        resim: 'sergi/Mavi 2020 25x30 Tüab18.jpg',
        name: 'Mavi',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '25x30 cm',
        year: 2020,
    },
    {
        id: 76,
        resim: 'sergi/Natürmort  35x50 tüyb 2022  19.jpg',
        name: 'Natürmort',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '35x50 cm',
        year: 2022,
    },
    {
        id: 77,
        resim: 'sergi/Nıse tüab 35x 50 2021    20.jpg',
        name: 'Nıse',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '35x50 cm',
        year: 2021,
    },
    {
        id: 78,
        resim: 'sergi/Portre 35x50 tüyb 2021    21.jpg',
        name: 'Portre',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '35x50 cm',
        year: 2021,
    },
    {
        id: 79,
        resim: 'sergi/Sessizlik 50x70 tüyb 2011  22.jpg',
        name: 'Sessizlik',
        style: "Tuval Üzerine Yağlı Boya ",
        dimensions: '50x70 cm',
        year: 2011,
    },
    {
        id: 80,
        resim: 'sergi/Zerafet tüab 25x35 2022    26.jpg',
        name: 'Zerafet',
        style: "Tuval Üzerine Akrilik Boya ",
        dimensions: '25x35 cm',
        year: 2022,
    },
];
export default worksData