import "./App.css";
import NavBar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import Works from "./Components/Works/Works";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Home from "./Components/Home/Home";
import Activities from "./Components/Activities/Activities";
import Footer from "./Components/Footer/Footer";


function App() {
  return (
    <>
      <Router>
        <NavBar />
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/works" element={<Works />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer/>
      </Router> 
  </>
  );
}

export default App;