import React from 'react';
import worksData from './WorksData.js';
import './Works.css';

class Works extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverIndex: -1,
    };
  }

  handleHover = (index) => {
    this.setState({ hoverIndex: index });
  };

  handleHoverOut = () => {
    this.setState({ hoverIndex: -1 });
  };

  render() {
    return (
      <div className="works-page">
        <h1>Eserlerim</h1>
        <ul className="works">
          {worksData.map((works, index) => (
            <li
              key={index}
              onMouseEnter={() => this.handleHover(index)}
              onMouseLeave={this.handleHoverOut}
            >
              <div className="image-container">
                <img className='img' src={works.resim} alt={works.name} loading="lazy" />
                {this.state.hoverIndex === index && (
                  <div className="description-container">
                    <p className="description">{works.description}</p>
                  </div>
                )}
              </div>
              <h2 >{works.name}</h2>
              <div className='works-detail'>
                <p>Teknik: {works.style}</p>
                <p>Boyutlar: {works.dimensions}</p>
                <p>Yıl: {works.year}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Works;
